(function ($) {
  Drupal.behaviors.gnavTransparentV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-gnav-transparent-v1', context);
      var style = $template.data('gnav-transparent-style');

      if (style.length) {
        $('body').addClass('gnav-transparent ' + style);
      }
    }
  };
})(jQuery);
